import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/agriculture/harvesting/x-series/',
    imageId: 'xSeriesImage',
    name: 'X Series Combines',
    alt: 'John Deere X Series Combines',
  },
  {
    link: '/agriculture/harvesting/s-series/',
    imageId: 'sSeriesImage',
    name: 'S Series Combines',
    alt: 'John Deere S Series Combines',
  },
  {
    link: '/agriculture/harvesting/corn-heads/',
    imageId: 'cornHeadsImage',
    name: 'Corn Heads',
    alt: 'John Deere Corn Heads',
  },
]

const HarvestingPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage, harvestEquipmentPUKImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Harvesting Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson is a supplier of John Deere Harvesting Equipment. We have a large inventory of new combines.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Agriculture Equipment',
                'item': 'https://www.hutsoninc.com/agriculture/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Harvesting',
                'item': 'https://www.hutsoninc.com/agriculture/harvesting/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Harvesting Equipment' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Harvesting Equipment' />

        <Videos
          type='Harvesting Equipment'
          id='QO8qTLf48Do'
          list='PLUz5fj7f_mw-8_b1GB6UwhQPCp-C0VtcR'
        />
      </Content>
      <FlexGrid>
        <div>
          <GatsbyImage
            image={getImage(harvestEquipmentPUKImage)}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: '100%', width: '100%' }}
            alt=''
          />
        </div>
        <FlexGridTextColumn>
          <H3 as='h2'>Harvest Equipment Precision Upgrades</H3>
          <P>
            Reduce grain loss at the header, maintain consistent harvest speed, and increase
            visibility with precision upgrades for your existing harvesting equipment.
          </P>
          <div>
            <Button
              as={ButtonOutboundLink}
              color='green'
              ghost
              href='https://www.deere.com/en/technology-products/precision-ag-technology/precision-upgrades/harvest-upgrades/'
            >
              Learn more at Deere.com
            </Button>
          </div>
        </FlexGridTextColumn>
      </FlexGrid>
    </Layout>
  )
}

const FlexGrid = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};

    > * {
      flex-grow: 0;
      flex-shrink: 0;
      width: 50%;
    }
  }
`

const FlexGridTextColumn = styled.div`
  background-color: ${props => props.theme.color.n20};
  padding: 36px 24px;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px;
  }
`

const ButtonOutboundLink = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 0;
  text-decoration: none;
`

export const pageQuery = graphql`
  query harvestingSubcategoryQuery($subcategory: String = "harvesting") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/harvesting-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    cornHeadsImage: file(relativePath: { eq: "agriculture/corn-head.jpg" }) {
      ...FloatingGridImage
    }
    sSeriesImage: file(relativePath: { eq: "agriculture/s-series.jpg" }) {
      ...FloatingGridImage
    }
    xSeriesImage: file(relativePath: { eq: "agriculture/x-series-combines.jpg" }) {
      ...FloatingGridImage
    }
    harvestEquipmentPUKImage: file(
      relativePath: {
        eq: "precision-upgrades/harvest-equipment-precision-upgrades-r4d092124_rrd.jpg"
      }
    ) {
      ...SharpImage900
    }
  }
`

export default HarvestingPage
